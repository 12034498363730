import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 720.000000 499.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,499.000000) scale(0.100000,-0.100000)">
<path d="M4947 4073 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z"/>
<path d="M4780 4063 c-25 -2 -52 -7 -60 -10 -8 -4 -44 -15 -80 -25 -56 -15
-59 -17 -22 -14 23 2 40 0 36 -5 -3 -5 10 -9 29 -9 28 0 33 3 33 23 -1 20 4
22 59 23 33 1 71 6 85 12 l25 11 -30 -1 c-16 -1 -50 -3 -75 -5z"/>
<path d="M5098 4062 c12 -2 22 -8 22 -13 0 -12 -12 -11 -65 2 -31 8 -49 8 -62
0 -12 -7 -13 -10 -4 -11 10 0 11 -3 2 -13 -8 -10 -7 -14 2 -15 6 0 21 -3 33
-7 13 -4 27 -1 37 9 8 9 27 16 41 16 14 0 26 5 26 10 0 6 17 10 38 9 28 0 32
-2 17 -8 -13 -5 -2 -8 33 -7 28 1 53 6 53 11 2 13 -62 24 -136 22 -33 0 -50
-3 -37 -5z"/>
<path d="M4878 4053 c-10 -2 -18 -13 -18 -24 0 -13 -7 -19 -24 -19 -14 0 -28
-4 -31 -10 -3 -5 -16 -10 -28 -10 -41 -1 5 -18 62 -24 37 -3 51 -1 48 7 -2 7
-19 11 -38 10 l-34 -2 33 14 c17 8 32 21 32 29 0 8 6 14 13 13 38 -1 67 4 67
13 0 10 -49 12 -82 3z"/>
<path d="M4750 4030 c-32 -7 -33 -7 -7 -9 16 0 26 -5 22 -11 -3 -5 4 -10 16
-10 17 0 19 3 10 14 -9 11 -9 15 1 19 18 7 0 6 -42 -3z"/>
<path d="M5308 4034 c6 -3 12 -11 12 -19 0 -19 27 -39 35 -25 3 5 12 10 18 9
9 0 9 -2 0 -6 -7 -2 -13 -9 -13 -15 0 -6 7 -8 15 -4 18 7 20 -10 3 -17 -10 -4
-10 -7 -1 -14 14 -10 50 -9 75 2 27 11 11 25 -17 15 -14 -5 -25 -6 -25 -1 0 5
5 11 11 13 16 5 -60 54 -96 61 -16 3 -24 4 -17 1z"/>
<path d="M4900 3965 c0 -8 -6 -15 -12 -15 -7 -1 -2 -7 12 -15 30 -17 40 -18
40 -5 0 6 -7 10 -15 10 -8 0 -15 7 -15 16 0 9 5 12 12 8 7 -4 8 -3 4 4 -10 17
-26 15 -26 -3z"/>
<path d="M4959 3953 c-5 -30 -2 -39 15 -49 15 -9 24 -9 38 0 27 16 22 33 -13
45 -38 13 -39 13 -40 4z m36 -23 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6
8 10 10 10 2 0 7 -4 10 -10z"/>
<path d="M5468 3943 c-17 -4 -35 -59 -24 -70 3 -4 6 1 6 10 0 10 5 17 10 17 6
0 10 -6 10 -13 0 -8 10 -13 22 -13 17 1 24 -6 28 -29 6 -29 6 -29 12 -6 7 26
31 24 36 -2 2 -10 -3 -17 -12 -17 -9 0 -16 -4 -16 -10 0 -15 19 -12 37 5 8 8
19 13 24 10 14 -9 11 -25 -6 -25 -9 0 -12 -6 -9 -15 5 -13 9 -13 22 -3 13 11
14 9 8 -12 -4 -14 -10 -34 -12 -45 l-4 -20 9 20 c5 11 14 31 20 45 10 24 10
24 4 -5 -6 -27 -5 -29 11 -16 15 13 15 16 0 40 -27 42 -74 83 -98 85 -12 0
-34 12 -47 25 l-24 23 23 -5 c12 -3 22 -3 22 0 0 6 -27 33 -32 32 -2 -1 -11
-3 -20 -6z"/>
<path d="M5025 3901 c-3 -5 -1 -12 4 -15 5 -4 12 -2 16 4 4 6 11 7 17 4 7 -4
8 -2 4 4 -9 14 -33 16 -41 3z"/>
<path d="M5345 3880 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5057 3872 c-5 -7 -14 -10 -20 -6 -7 3 -1 -4 13 -15 14 -11 30 -19
37 -16 7 2 10 -2 7 -10 -4 -9 1 -13 13 -12 19 1 29 27 10 27 -5 0 -7 7 -4 15
5 11 1 12 -18 5 -19 -7 -24 -6 -27 8 -3 13 -5 14 -11 4z"/>
<path d="M5205 3860 c7 -12 55 -14 55 -2 0 4 -14 9 -31 10 -19 2 -28 -2 -24
-8z"/>
<path d="M4357 3819 c-231 -24 -566 -158 -853 -342 -136 -87 -219 -149 -367
-276 l-88 -76 -262 -5 -262 -5 -31 -115 c-17 -63 -34 -127 -38 -142 l-6 -28
215 0 c175 0 215 -3 215 -14 0 -7 -25 -107 -56 -222 -31 -115 -67 -247 -79
-294 -13 -47 -55 -205 -94 -351 -39 -146 -71 -271 -71 -277 0 -9 50 -12 184
-12 167 0 185 2 190 18 5 17 40 149 75 282 11 41 67 252 125 468 58 216 106
395 106 398 0 2 99 4 219 4 l219 0 11 33 c21 68 61 225 61 241 0 14 -34 16
-340 16 -209 0 -340 4 -340 10 0 16 181 130 305 191 149 73 284 122 440 159
562 136 1030 32 1184 -264 35 -65 35 -63 21 82 -29 302 -192 487 -460 522 -85
11 -114 11 -223 -1z"/>
<path d="M5946 3765 c104 -261 90 -528 -46 -822 -32 -69 -134 -240 -194 -325
-194 -272 -571 -640 -976 -950 -254 -194 -734 -521 -994 -677 -65 -39 -113
-71 -106 -71 36 0 523 212 765 333 518 259 892 513 1186 807 80 80 175 183
211 230 328 425 433 840 306 1215 -28 82 -101 223 -138 265 l-30 35 16 -40z"/>
<path d="M5147 3782 c-5 -7 1 -23 15 -41 13 -16 28 -37 35 -47 11 -17 12 -17
12 -1 1 9 -4 17 -11 17 -9 0 -9 3 0 12 17 17 15 29 -3 22 -11 -5 -13 -2 -8 12
5 13 3 16 -10 11 -10 -4 -17 0 -19 11 -3 13 -5 14 -11 4z"/>
<path d="M5646 3729 c-9 -13 -16 -19 -16 -14 0 6 -4 4 -9 -4 -11 -17 1 -63 19
-69 10 -4 12 3 7 24 -3 17 0 37 8 48 13 17 15 15 25 -23 6 -23 8 -46 5 -51 -3
-6 1 -10 9 -10 13 0 13 6 2 43 -7 24 -17 51 -23 61 -10 17 -11 17 -27 -5z"/>
<path d="M5245 3688 c4 -13 8 -33 8 -45 1 -12 5 -25 10 -29 4 -4 7 -14 7 -23
0 -15 -9 1 -38 69 -9 21 -11 22 -11 5 -1 -11 10 -40 24 -64 14 -23 25 -55 26
-70 0 -23 1 -24 9 -6 5 11 9 14 9 8 1 -18 21 -16 21 2 0 8 -6 15 -14 15 -19 0
-23 15 -22 92 1 60 -1 68 -18 68 -14 0 -17 -5 -11 -22z"/>
<path d="M5665 3612 c-14 -10 -18 -100 -5 -120 9 -14 10 -12 9 13 -2 45 0 52
11 35 8 -12 10 -11 9 5 -2 41 -14 74 -24 67z"/>
<path d="M5697 3522 c3 -112 13 -137 13 -33 0 52 3 70 12 65 8 -5 9 0 5 17 -4
13 -7 27 -7 30 0 4 -6 9 -13 12 -10 4 -12 -16 -10 -91z"/>
<path d="M5722 3470 c2 -41 1 -95 -2 -120 l-7 -45 -1 48 c-2 54 -18 55 -28 2
-4 -25 -2 -39 10 -50 9 -9 16 -25 16 -36 0 -32 -22 -112 -38 -137 -8 -12 -12
-33 -10 -46 4 -17 1 -23 -9 -19 -15 6 -17 -6 -3 -27 13 -20 15 -16 40 80 41
152 62 372 40 407 -9 14 -10 2 -8 -57z"/>
<path d="M5314 3488 c-4 -7 -4 -10 1 -6 10 9 31 -13 38 -38 2 -10 5 -2 6 19 1
30 -2 37 -18 37 -11 0 -23 -6 -27 -12z"/>
<path d="M5420 3435 c0 -25 5 -45 10 -45 6 0 10 20 10 45 0 25 -4 45 -10 45
-5 0 -10 -20 -10 -45z"/>
<path d="M5294 3463 c10 -11 36 -12 36 -2 0 5 -10 9 -22 9 -12 0 -18 -3 -14
-7z"/>
<path d="M5300 3415 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M5317 3270 c3 -72 1 -141 -5 -163 -6 -20 -7 -37 -2 -37 5 0 11 10 13
23 3 13 5 7 6 -16 0 -24 5 -36 11 -32 6 4 8 16 4 27 -4 14 -2 18 5 14 6 -4 11
0 11 9 0 9 -4 13 -10 10 -6 -4 -10 6 -10 22 0 19 3 24 9 14 12 -18 21 47 10
74 -7 18 -8 18 -10 -5 -2 -36 -17 43 -19 100 -1 25 -5 54 -10 65 -4 11 -6 -36
-3 -105z"/>
<path d="M5335 3381 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M5602 3359 c-1 -16 -1 -29 2 -29 7 0 13 50 7 55 -4 2 -8 -10 -9 -26z"/>
<path d="M5684 3257 c1 -35 3 -43 10 -28 5 11 4 31 -2 48 -9 25 -10 24 -8 -20z"/>
<path d="M5661 3259 c-1 -9 -8 -19 -16 -23 -19 -7 -17 -63 3 -71 7 -2 11 -1 8
4 -3 4 -1 25 5 45 6 20 8 43 5 49 -2 7 -5 5 -5 -4z"/>
<path d="M5607 3130 c3 -11 9 -17 14 -14 14 8 10 34 -5 34 -9 0 -12 -7 -9 -20z"/>
<path d="M1812 3107 c-7 -7 -58 -85 -114 -173 -57 -87 -130 -201 -163 -252
-33 -51 -102 -157 -153 -235 -51 -78 -120 -186 -155 -240 -34 -54 -101 -157
-148 -230 -152 -234 -199 -308 -199 -313 0 -2 91 -3 202 -2 l202 3 80 128 79
127 261 -2 261 -3 12 -124 c7 -68 14 -126 16 -128 2 -2 94 -2 203 -1 l200 3
-33 290 c-18 160 -41 376 -53 480 -11 105 -29 271 -41 370 -11 99 -23 209 -27
245 l-7 65 -206 3 c-163 2 -209 0 -217 -11z m114 -639 c7 -106 13 -212 14
-235 l0 -43 -160 0 c-103 0 -160 4 -160 10 0 6 53 97 118 203 65 105 129 209
141 230 13 21 26 36 28 33 3 -3 12 -92 19 -198z"/>
<path d="M4062 3030 c-31 -49 -111 -172 -177 -274 -66 -102 -163 -251 -215
-332 -52 -81 -126 -196 -165 -255 -38 -60 -104 -161 -145 -226 -41 -65 -99
-153 -127 -195 -29 -43 -53 -81 -53 -83 0 -3 91 -5 203 -5 l202 0 69 113 c38
61 76 120 84 130 14 15 40 17 272 17 l257 0 6 -57 c4 -32 9 -91 13 -130 l7
-73 61 0 c55 0 65 3 88 28 58 62 218 289 218 309 0 19 -54 513 -94 868 -9 72
-18 158 -22 193 l-7 62 -209 0 -209 0 -57 -90z m172 -635 c7 -104 11 -193 9
-197 -5 -10 -323 -11 -323 -2 0 4 64 113 143 242 l142 236 8 -45 c4 -24 13
-129 21 -234z"/>
<path d="M5419 3078 c-2 -18 1 -40 7 -58 5 -13 7 -13 15 2 6 10 5 26 -1 42
-10 28 -19 34 -21 14z"/>
<path d="M5610 3050 c0 -11 2 -20 4 -20 2 0 6 9 9 20 3 11 1 20 -4 20 -5 0 -9
-9 -9 -20z"/>
<path d="M5625 3010 c-8 -44 -9 -44 -16 -15 -6 26 -7 27 -7 5 0 -14 3 -36 7
-50 8 -25 8 -24 24 12 10 25 13 46 8 65 -8 27 -8 26 -16 -17z"/>
<path d="M5301 2989 c1 -46 2 -49 8 -23 4 17 11 35 14 41 4 6 0 16 -8 23 -13
11 -15 5 -14 -41z"/>
<path d="M5351 3018 c0 -14 -6 -28 -13 -30 -7 -3 -9 -11 -4 -24 7 -16 10 -15
18 14 5 19 7 41 5 50 -3 10 -5 5 -6 -10z"/>
<path d="M5275 2920 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5370 2926 c0 -2 9 -6 20 -9 11 -3 20 -1 20 4 0 5 -9 9 -20 9 -11 0
-20 -2 -20 -4z"/>
<path d="M5496 2904 c-3 -7 5 -23 17 -34 12 -12 22 -28 22 -37 0 -9 7 -17 16
-18 21 -4 63 91 44 99 -8 3 -15 -7 -19 -29 -10 -49 -29 -57 -22 -8 l6 38 -15
-30 c-14 -29 -19 -27 -16 6 2 21 -26 32 -33 13z"/>
<path d="M5270 2888 c0 -7 -14 -57 -31 -111 -24 -77 -28 -101 -18 -107 21 -14
40 0 29 20 -12 23 -13 63 -1 56 12 -8 22 34 15 64 -5 20 -3 22 5 10 8 -12 11
-4 11 33 0 26 -2 47 -5 47 -3 0 -5 -5 -5 -12z"/>
<path d="M5466 2844 c-8 -29 5 -50 23 -40 6 4 11 20 11 37 0 37 -25 39 -34 3z"/>
<path d="M5517 2780 c-3 -11 -1 -20 4 -20 5 0 9 9 9 20 0 11 -2 20 -4 20 -2 0
-6 -9 -9 -20z"/>
<path d="M5541 2784 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5439 2788 c-6 -20 -6 -38 1 -38 4 0 6 -10 4 -22 -4 -21 -4 -22 5 -3
13 27 13 65 1 65 -5 0 -10 -1 -11 -2z"/>
<path d="M5477 2743 c-4 -3 -7 -13 -7 -21 0 -12 3 -12 15 -2 8 7 15 16 15 21
0 11 -14 12 -23 2z"/>
<path d="M5494 2710 c-10 -22 -21 -40 -24 -40 -4 0 -12 -12 -19 -27 -10 -21
-14 -24 -21 -13 -8 12 -10 12 -15 -2 -4 -9 -8 -23 -9 -32 -3 -19 -16 -34 -16
-18 0 7 -4 12 -10 12 -13 0 -13 -21 0 -40 9 -13 12 -12 25 5 8 11 18 25 22 30
7 9 75 116 97 153 4 6 3 12 -3 12 -5 0 -17 -18 -27 -40z"/>
<path d="M5178 2608 c-10 -23 -16 -44 -13 -46 3 -3 11 10 19 29 9 19 15 28 16
21 0 -8 -8 -30 -17 -50 -13 -26 -14 -37 -5 -40 7 -2 10 -8 6 -13 -3 -5 0 -9 6
-9 7 0 9 8 5 19 -4 13 1 27 13 39 13 13 18 28 14 50 -2 18 -8 30 -12 27 -5 -3
-10 -1 -12 4 -1 6 -11 -8 -20 -31z"/>
<path d="M5271 2614 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5310 2580 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M5343 2525 c-6 -14 -7 -23 -2 -19 13 8 11 -10 -3 -24 -14 -14 -37 12
-38 41 0 27 -23 15 -31 -15 -5 -19 -3 -28 8 -32 11 -4 13 0 9 17 -6 22 -6 22
6 -1 13 -26 5 -48 -26 -66 -13 -9 -20 -7 -30 7 -20 26 -48 21 -76 -13 -30 -36
-65 -33 -54 4 6 21 6 21 -9 1 -11 -14 -12 -22 -4 -27 6 -4 11 -24 10 -45 l-1
-38 13 30 c7 17 14 26 15 20 0 -5 7 1 15 15 7 14 16 24 19 22 4 -1 19 4 36 13
26 14 30 13 38 -2 6 -10 16 -14 26 -10 23 9 21 5 -24 -44 -41 -45 -52 -47 -24
-4 9 13 14 30 11 37 -3 7 -10 -2 -17 -20 -11 -29 -16 -33 -39 -27 -14 3 -21 2
-15 -2 6 -4 13 -16 17 -25 9 -26 32 -22 59 11 64 79 73 92 73 108 0 10 6 18
13 18 21 0 60 55 55 76 -7 26 -16 24 -30 -6z"/>
<path d="M5147 2533 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M5122 2478 c-7 -7 -12 -15 -12 -19 0 -11 33 -4 37 8 6 17 -12 24 -25
11z"/>
<path d="M5066 2375 c-3 -9 0 -15 9 -15 9 0 12 6 9 15 -4 8 -7 15 -9 15 -2 0
-5 -7 -9 -15z"/>
<path d="M5023 2294 c-6 -13 -7 -24 -3 -24 9 0 25 39 18 45 -2 2 -8 -7 -15
-21z"/>
<path d="M5100 2288 c4 -48 0 -57 -27 -60 -26 -3 -28 -6 -30 -54 l-1 -52 29
30 c16 17 26 34 24 39 -3 4 -1 10 5 14 6 4 8 11 5 16 -4 5 -1 9 4 9 6 0 11 -6
11 -12 0 -7 12 -1 25 15 28 31 25 49 -11 66 -34 15 -36 14 -34 -11z m45 -38
c-10 -11 -22 -17 -27 -14 -5 4 1 12 14 19 32 18 34 18 13 -5z m-70 -76 c-9 -9
-19 -13 -23 -10 -3 4 0 11 8 16 24 15 33 11 15 -6z"/>
<path d="M4996 2245 c-3 -8 -1 -15 3 -15 5 0 11 7 15 15 3 8 1 15 -3 15 -5 0
-11 -7 -15 -15z"/>
<path d="M4960 2179 c-54 -72 -63 -91 -49 -100 11 -6 11 -11 2 -20 -10 -10
-14 -9 -19 4 -5 15 -9 14 -25 -7 -10 -13 -19 -28 -19 -33 0 -4 -14 -23 -30
-42 -21 -24 -29 -41 -25 -55 5 -16 4 -18 -4 -7 -8 13 -13 11 -30 -10 -11 -13
-18 -30 -16 -38 4 -8 0 -11 -9 -8 -11 4 -13 1 -9 -10 4 -10 2 -14 -4 -10 -11
7 -68 -51 -59 -60 2 -3 15 5 28 18 13 13 37 32 52 43 19 12 25 21 17 25 -9 6
-8 11 3 20 8 7 19 10 24 7 5 -3 19 5 31 18 23 24 14 45 -11 30 -7 -4 0 7 16
24 17 18 34 32 40 32 5 0 -1 -11 -13 -24 -15 -16 -19 -26 -11 -31 11 -7 39 21
40 40 1 17 40 58 48 51 4 -4 -4 -16 -17 -27 -13 -11 -22 -22 -19 -24 7 -8 58
46 58 61 0 7 5 16 12 20 7 5 8 3 3 -6 -5 -8 -4 -11 3 -6 6 4 8 14 5 22 -5 13
-1 15 14 10 14 -5 23 0 33 19 7 14 10 28 6 32 -9 9 -27 -18 -19 -30 3 -5 -4
-6 -15 -3 -14 5 -24 1 -30 -10 -6 -11 -16 -15 -27 -11 -21 8 -14 33 8 31 13
-1 15 4 10 24 -5 21 -3 24 11 19 18 -7 30 11 24 39 -2 14 -8 10 -28 -17z"/>
<path d="M4930 2100 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
